import { HttpClient, HttpEvent, HttpEventType, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class LogoService {

  constructor(private http: HttpClient) { }

  public uploadLogo(eventId: any, file: File): Observable<HttpEvent<{}>> {
    const formData = new FormData();
    formData.append('logo', file);

    const options = {
      reportProgress: true,
      withCredentials: true
    };

    const req = new HttpRequest(
      'POST',
      `${environment.apiUrl}logo/upload/${eventId}`,
      formData,
      options
    );
    return this.http.request(req);
  }




}
