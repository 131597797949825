import { createAction, props } from "@ngrx/store";

export const UPLOAD_REQUEST = createAction(
    '[Upload Event Logo] Request',
    props<{ id: string, file: File; }>()
);

export const UPLOAD_CANCEL = createAction(
    '[Upload Event Logo] Canceled'
);

export const UPLOAD_RESET = createAction(
    '[Upload Event Logo] Reset'
);


export const UPLOAD_STARTED = createAction(
    '[Upload Event Logo] Started'
);

export const UPLOAD_PROGRESS = createAction(
    '[Upload Event Logo] Progress',
    props<{ progress: number }>()
);

export const UPLOAD_FAILURE = createAction(
    '[Upload Event Logo] Failure',
    props<{ error: string }>()
);

export const UPLOAD_COMPLETED = createAction(
    '[Upload Event Logo] Completed'
);
