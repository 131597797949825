<div *ngIf="!isHidden" class="bg-gray-50 p-4 flex">
    <div class="mr-auto pr-3">
        <div class="-mx-1.5 -my-1.5">
            <button type="button" (click)="hideSidepot(data._id)"
                class="inline-flex bg-gray-50 rounded-md p-1.5 text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-600">
                <span class="sr-only">Dismiss</span>
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                </svg>
            </button>
        </div>
    </div>
    <div>
        <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div class="col-span-2">
                <label for="name" class="block text-sm font-medium leading-6 text-gray-900">Name*</label>
                <div class="mt-2">
                    <div
                        class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 bg-white focus-within:ring-2 focus-within:ring-inset focus-within:ring-emerald-600 sm:max-w-md">
                        <input type="text" name="name" id="name" [ngModel]="data['name']"
                            (ngModelChange)='updateSidepotName(data, $event)'
                            class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder="Open 4D">
                    </div>
                </div>
            </div>

            <div class="col-span-2">
                <label for="price" class="block text-sm font-small leading-6 text-gray-900">Price</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <input type="text" name="price" id="price" [ngModel]="data['price']"
                        (ngModelChange)="updateSidepotPrice(data, $event)"
                        class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                        placeholder="0.00" aria-describedby="price-currency">
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <span class="text-gray-500 sm:text-sm" id="price-currency">USD</span>
                    </div>
                </div>
            </div>

            <div class="col-span-2">
                <label for="sidepotDate" class="block text-sm font-medium leading-6 text-gray-900">Date</label>
                <select id="sidepotDate" name="sidepotDate" [ngModel]="data['date']"
                (ngModelChange)="updateSidepotDate(data, $event)"
                    class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-emerald-600 sm:text-sm sm:leading-6">
                    <option *ngFor="let day of days;" value="{{day['initial']}}">{{day['formatted']}}</option>
                </select>
            </div>

            <div class="col-span-2">
                <div class="relative flex items-start">
                    <div class="flex h-6 items-center">
                      <input [ngModel]="data['drawSelection']"
                      (ngModelChange)="updateSidepotDrawSelection(data, $event)" id="sidepotDrawSelection" aria-describedby="Sidepot has draw selection" name="sidepotDrawSelection" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-600">
                    </div>
                    <div class="ml-3 text-sm leading-6">
                      <label for="comments" class="font-medium text-gray-900">Draw Selection</label>
                      <p id="comments-description" class="text-gray-500">Sets this sidepot as a draw selection so users can only select one</p>
                    </div>
                  </div>
            </div>

            <div class="col-span-2">
                <div class="relative flex items-start">
                    <div class="flex h-6 items-center">
                      <input [ngModel]="data['hasQuantityLock']"
                      (ngModelChange)="updateSidepotHasQuantityLock(data, $event)" id="sidepotLimitedQuantity" aria-describedby="Sidepot has limited quantity" name="sidepotLimitedQuantity" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-600">
                    </div>
                    <div class="ml-3 text-sm leading-6">
                      <label for="comments" class="font-medium text-gray-900">Limited quantity</label>
                      <p id="comments-description" class="text-gray-500">Allows you to set a limit to how many entries can be accepted.</p>
                    </div>
                  </div>
            </div>

            <div *ngIf="data['hasQuantityLock']" class="col-span-2">
                <label for="quantity" class="block text-sm font-small leading-6 text-gray-900">Quantity</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <input type="text" name="quantity" id="quantity" [ngModel]="data['quantity']"
                        (ngModelChange)="updateSidepotQuantity(data, $event)"
                        class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                        placeholder="0" aria-describedby="Sidepot quantity">
                </div>
            </div>


        </div>

    </div>
    <div class="ml-auto pl-3">
        <div class="-mx-1.5 -my-1.5">
            <button type="button" (click)="removeSidepot(data._id)"
                class="inline-flex bg-gray-50 rounded-md p-1.5 text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-600">
                <span class="sr-only">Dismiss</span>
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                    aria-hidden="true">
                    <path fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd" />
                </svg>
            </button>
        </div>
    </div>


</div>


<div *ngIf="isHidden" class="bg-gray-50 p-4 flex">
    <div class="mr-auto pr-3">
        <div class="-mx-1.5 -my-1.5">
            <button type="button" (click)="showSidepot(data._id)"
                class="inline-flex bg-gray-50 rounded-md p-1.5 text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-600">
                <span class="sr-only">Dismiss</span>
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                </svg>
            </button>
        </div>
    </div>
    <div>
        <div class="-ml-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
            <div class="ml-4">
                <h3 class="text-md text-gray-900">
                    {{data.name}}
                </h3>
            </div>
        </div>
    </div>
    <div class="ml-auto pl-3">
        <div class="-mx-1.5 -my-1.5">
            <button type="button" (click)="removeSidepot(data._id)"
                class="inline-flex bg-gray-50 rounded-md p-1.5 text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-600">
                <span class="sr-only">Dismiss</span>
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                    aria-hidden="true">
                    <path fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd" />
                </svg>
            </button>
        </div>
    </div>


</div>