import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/reducers/AppState';
import * as uuid from "uuid";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Update } from '@ngrx/entity';
import { SidePot } from 'src/app/core/interfaces/SidePot';
import { addSidepot, updateSidepotOrder } from 'src/app/store/actions/sidepots.actions';
import { selectSidepots } from 'src/app/store/selectors/sidepots.selectors';
import { Subject, filter, map, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'mefe-sidepots',
  templateUrl: './sidepots.component.html',
  styleUrls: ['./sidepots.component.scss']
})
export class SidepotsComponent implements OnInit, OnDestroy {


  destroy$: Subject<boolean> = new Subject<boolean>();

  sidepots: SidePot[] = [];
  allSidepots: SidePot[] = [];
  @Input() classId: any;
  @Input() days: any = [];

  constructor(private store: Store<AppState>) {

  }

  ngOnInit(): void {

    this.store.select(selectSidepots).pipe(
      tap(sidepots => this.allSidepots = sidepots),
      map(sidepots => sidepots.filter(sidepot => sidepot.eventClass === this.classId)),
      takeUntil(this.destroy$)
    ).subscribe(sidepots => {
        this.sidepots = sidepots;
      });

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  addSidepot() {
    this.store.dispatch(addSidepot({ id: uuid.v4(), orderId: this.allSidepots.length, classId: this.classId }));
  }


  custom(index: number, item: any) {
    return index;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.sidepots, event.previousIndex, event.currentIndex);

    const sidepotsUpdate: Update<SidePot>[] = this.sidepots.map((item: SidePot, index) => {
      return {
        id: item._id,
        changes: {
          orderId: index
        }
      };
    });

    this.store.dispatch(updateSidepotOrder({ payload: sidepotsUpdate }));

  }

}
