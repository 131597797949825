<div class="sm:col-span-2 sm:col-start-1">
    <label for="price" class="block text-sm font-small leading-6 text-gray-900">{{data['formatted']}}</label>
    <div class="relative mt-2 rounded-md shadow-sm">
        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span class="text-gray-500 sm:text-sm">$</span>
        </div>
        <input type="text" name="price" id="price" [ngModel]="price" (ngModelChange)="updateClassPrice(data['initial'], $event)"
            class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
            placeholder="0.00" aria-describedby="price-currency" >
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span class="text-gray-500 sm:text-sm" id="price-currency">USD</span>
        </div>
    </div>
</div>