import { createAction, props } from '@ngrx/store';
import { Organization } from '../../core/interfaces/Organization';

export const getOrganization = createAction(
    '[Organization] Get',
    props<{ id: any; }>()
);

export const getOrganizationSuccess = createAction(
    '[Organization] Get Success',
    props<{ data: Organization; }>()
);

export const getOrganizationFailure = createAction(
    '[Organization] Get Failure',
    props<{ error: string }>()
);
