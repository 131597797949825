import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Organization } from '../interfaces/Organization';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class OrganizationService {

  constructor(private http: HttpClient) { }

  getEvent(id: any) {
    return this.http.get<Organization>(environment.apiUrl + 'company/' + id);
  }

}
