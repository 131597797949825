
import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers/AppState';
import * as fromClasses from '../reducers/classes.reducers';

export const selectClassesState = (state: AppState) => state.classes;

export const selectClasses = createSelector(
    selectClassesState,
    fromClasses.selectAll
);
