<div class="mb-16 bg-white py-8 px-4 shadow sm:rounded-lg sm:px-4 space-y-8 divide-y divide-gray-200">
    <div class="space-y-6 sm:space-y-5">
        <h3 class="text-lg leading-6 font-medium text-emerald-600">
            Entry Form Builder
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Add classes, sidepots and rollovers below as well as additional items avaliable for sale.
        </p>

        <div>
            <h3 class="text-lg leading-6 font-medium text-emerald-600">
                Classes
            </h3>
        </div>


        <div class="mt-4">
            <mefe-classes></mefe-classes>
            <!-- <mef-entry-contestants [eventData]="eventData"></mef-entry-contestants> -->
        </div>

        <div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">

            <div>
                <h3 class="text-lg leading-6 font-medium text-emerald-600">
                    Additional Items
                </h3>
            </div>

            <div class="mt-4">
                <mefe-additional-items></mefe-additional-items>
            </div>
            
        </div>



    </div>

    <mef-bottom-navigation (onBack)="backToEvent()" [continueLabel]="'Preview'" (onContinue)="previewReceipt()"
        [backLabel]="'Back To Event'"></mef-bottom-navigation>

</div>