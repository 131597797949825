import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationService } from 'src/app/core/services/organization.service';
import * as moment from 'moment';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/reducers/AppState';
import { getOrganization } from 'src/app/store/actions/organization.actions';
import { selectOrganization } from 'src/app/store/selectors/organization.selectors';
import { Observable } from 'rxjs/internal/Observable';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { updateEventInfo } from 'src/app/store/actions/event.actions';
import { selectEvent, selectEventId } from 'src/app/store/selectors/event.selectors';
import { getEditData, saveEdit, saveEditSuccess, setEditStatus } from 'src/app/store/actions/edit.actions';
import { UserService } from 'src/app/core/services/user.service';
import { UPLOAD_REQUEST } from 'src/app/store/actions/logo.actions';
import { selectLogoState } from 'src/app/store/selectors/logo.selectors';
import { selectEditStatus } from 'src/app/store/selectors/edit.selectors';
import { submitForm, submitFormSuccess } from 'src/app/store/actions/form.actions';
import { Actions, ofType } from '@ngrx/effects';
import { Subject, map, takeUntil } from 'rxjs';
import { clearStore } from 'src/app/store/actions/helper.actions';

@Component({
  selector: 'app-receipt-preview',
  templateUrl: './receipt-preview.component.html',
  styleUrls: ['./receipt-preview.component.scss']
})
export class ReceiptPreviewComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();

  eventId: string = "";
  isEdit: boolean = false;
  eventData: FormGroup;

  

  public items: any = [
    { name: "Example Class 1", quantity: 1, price: 35 },
    { name: "Example Class 2", quantity: 1, price: 50 },
    { name: "Stall", quantity: 2, price: 150 }
  ];

  constructor(private store: Store<AppState>, private userservice: UserService, private _formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, public organizationService: OrganizationService, private actions$: Actions) {
    this.eventData = this._formBuilder.group({
      eventExtraText: ['', [Validators.required]],
      emailFooterText: ['', [Validators.required]],
    });

  }

  ngOnInit(): void {
    this.store.select(selectEventId)
    .pipe(takeUntil(this.destroy$)).subscribe(eventId => {
      this.eventId = eventId;
    });

    this.store.select(selectEditStatus)
    .pipe(takeUntil(this.destroy$)).subscribe(status => {
      this.isEdit = status;
    });

    this.store.pipe(select(selectEvent))
    .pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.eventData.patchValue(data);
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }



  backToForm() {
    this.router.navigate(['/form']);
  }

  submit() {
    this.store.dispatch(updateEventInfo({ data: this.eventData.value }))

    if (this.isEdit) {
      this.store.dispatch(saveEdit({ id: this.eventId }));
    } else {
      this.store.dispatch(submitForm());
    }

    this.actions$.pipe(ofType(submitFormSuccess, saveEditSuccess), map((data: any) => {
      console.log('This is being called');
      this.store.dispatch(clearStore());
      this.router.navigate(['/edit', data.eventId]);
    })).subscribe();


  }

}
