import { Action, ActionReducer, ActionReducerMap, INIT } from '@ngrx/store';
import { organizationReducers } from "./organization.reducers";
import { eventReducers } from "./event.reducers";
import { classesReducer } from "./classes.reducers";
import { additionalItemsReducer } from "./additional-items.reducers";
import { classPriceReducer } from "./class-price.reducers";
import { sidepotsReducer } from "./sidepots.reducers";
import { rolloversReducer } from "./rollovers.reducers";
import { EditReducers } from "./edit.reducers";
import { clearStore } from "../actions/helper.actions";
import { logoUploadReducers } from './logo-upload.reducers';

export interface AppState {
    organization: any;
    event: any;
    classes: any;
    additionalItems: any;
    classPrices: any;
    sidepots: any;
    rollovers: any;
    edit: any;
    logo: any;
}

export const reducers: ActionReducerMap<AppState> = {
    organization: organizationReducers,
    event: eventReducers,
    classes: classesReducer,
    classPrices: classPriceReducer,
    sidepots: sidepotsReducer,
    additionalItems: additionalItemsReducer,
    rollovers: rolloversReducer,
    edit: EditReducers,
    logo: logoUploadReducers
};


export function clearStateMetaReducer<State extends {}>(reducer: ActionReducer<State>): ActionReducer<State> {
    return (state, action) => {
      if ( action != null && action.type === "[Helper] Clear Store") {
        return reducer( undefined, {type: INIT});
      }
      return reducer(state, action);
    };
  }