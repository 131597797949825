import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EventComponent } from './components/event/event.component';
import { FormComponent } from './components/form/form.component';
import { AuthGuard } from './core/guards';
import { ReceiptPreviewComponent } from './components/receipt-preview/receipt-preview.component';

const routes: Routes = [
  {
    path: 'edit/:id',
    component: EventComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'event/:id',
    component: EventComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'form',
    component: FormComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'receipt-preview',
    component: ReceiptPreviewComponent,
    canActivate: [AuthGuard]
  }
  // { path: '',   redirectTo: '/event', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
