import { createSelector, select } from '@ngrx/store';
import { AppState } from '../reducers/AppState';
import * as fromRollovers from '../reducers/rollovers.reducers';
import { filter, pipe } from 'rxjs';

export const selectRolloversState = (state: AppState) => state.rollovers;

export const selectRollovers = createSelector(
    selectRolloversState,
    fromRollovers.selectAll
);

export const selectRolloversByClassId = (classId: string) =>
  createSelector(selectRollovers, state => state.filter(x => x.class == classId));
