import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppState } from 'src/app/store/reducers/AppState';

@Injectable({
  providedIn: 'root'
})

export class EditService {

  constructor(private http: HttpClient) { }

  getData(id: string) {
    return this.http.get<AppState>(environment.apiUrl + 'event-builder/edit/' + id);
  }

  saveEdit(id: string, data: any) {
    return this.http.put<AppState>(environment.apiUrl + 'event-builder/edit/' + id, {...data});
  }


}
