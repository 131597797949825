import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Class } from 'src/app/core/interfaces/Class';
import { Event } from 'src/app/core/interfaces/Event';
import { editClassDescription, editClassName, removeClass } from 'src/app/store/actions/classes.actions';
import { AppState } from 'src/app/store/reducers/AppState';
import { selectEvent } from 'src/app/store/selectors/event.selectors';
import { selectClassPrices } from 'src/app/store/selectors/class-price.selectors';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'mefe-class',
  templateUrl: './class.component.html',
  styleUrls: ['./class.component.scss']
})
export class ClassComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() data: Class;
  @Input() days: any = [];
  isHidden: boolean = false;
  classPrices: any = {};

  constructor(private store: Store<AppState>) {
  }

  ngOnInit(): void {

    this.store.select(selectClassPrices)
    .pipe(takeUntil(this.destroy$)).subscribe(classPrices => {
      this.classPrices = classPrices;
    })
    
    if (this.data['name']) {
      this.isHidden = true;
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  removeClass(id: string) {
    this.store.dispatch(removeClass({ classId: id }))
  }

  updateClassName(classData: any, $event: any) {
    const update = {
      id: classData._id,
      changes: {
        name: $event
      }
    }
    this.store.dispatch(editClassName({ update: update }));
  }

  updateClassDescription(classData: any, $event: any) {
    const update = {
      id: classData._id,
      changes: {
        description: $event
      }
    }
    this.store.dispatch(editClassDescription({ update: update }));
  }



  showClass(id: any) {
    this.isHidden = false;
  }

  hideClass(id: any) {
    this.isHidden = true;
  }

}
