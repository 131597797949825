import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/reducers/AppState';

@Component({
  selector: 'mef-bottom-navigation',
  templateUrl: './bottom-navigation.component.html',
  styleUrls: ['./bottom-navigation.component.scss']
})
export class BottomNavigationComponent implements OnInit {


  @Input() isInvalid: boolean = false;
  @Input() continueLabel: string = "Continue";
  @Input() backLabel: string = "Back";
  @Input() additionalText: string;
  @Input() disableButtons: boolean = false;
  @Input() disableBack: boolean = false;
  
  @Output() onContinue = new EventEmitter();
  @Output() onBack = new EventEmitter();

  constructor(private store: Store<AppState>) { 
  }

  ngOnInit(): void {
  }

  continue() {
    this.onContinue.emit();
  }

  back() {
    this.onBack.emit();
  }

}
