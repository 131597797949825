import { createAction, props } from '@ngrx/store';

export const submitForm = createAction('[Form] Submit');


export const submitFormSuccess = createAction(
    '[Form] Submit Success',
    props<{ eventId: any; }>()
);

export const submitFormFailure = createAction(
    '[Form] Submit Failure',
    props<{ error: string }>()
);
