import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Class } from 'src/app/core/interfaces/Class';
import { addClass, updateClassesOrder } from 'src/app/store/actions/classes.actions';
import { AppState } from 'src/app/store/reducers/AppState';
import { selectClasses } from 'src/app/store/selectors/classes.selectors';
import * as uuid from "uuid";
import { CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray } from '@angular/cdk/drag-drop';
import { Update } from '@ngrx/entity';
import * as moment from 'moment';
import { selectEvent } from 'src/app/store/selectors/event.selectors';
import { Event } from 'src/app/core/interfaces/Event';

@Component({
  selector: 'mefe-classes',
  templateUrl: './classes.component.html',
  styleUrls: ['./classes.component.scss']
})
export class ClassesComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();

  classes: Class[] = [];
  eventData: Event;
  days: any = [];

  constructor(private store: Store<AppState>) {
  }

  ngOnInit(): void {
    this.store.select(selectEvent)
      .pipe(takeUntil(this.destroy$)).subscribe(eventData => {
        this.eventData = eventData;
        this.days = this.getDatesBetweenDates(this.eventData['start'], this.eventData['end']);
      })

    this.store.select(selectClasses)
      .pipe(takeUntil(this.destroy$)).subscribe(classes => {
        this.classes = classes;
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  addClass() {
    this.store.dispatch(addClass({ classId: uuid.v4(), orderId: this.classes.length }));
  }


  custom(index: number, item: any) {
    return index;
  }


  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.classes, event.previousIndex, event.currentIndex);

    const classUpdates: Update<Class>[] = this.classes.map((item: Class, index) => {
      return {
        id: item._id,
        changes: {
          orderId: index
        }
      };
    });

    this.store.dispatch(updateClassesOrder({ payload: classUpdates }));

  }

  getDatesBetweenDates(startDate: any, endDate: any) {
    let dates: any = []
    //to avoid modifying the original date
    var theDate = moment(startDate, "MM-DD-YYYY")
    const lastDate = moment(endDate, "MM-DD-YYYY");
    while (theDate < lastDate) {
      dates.push({ "formatted": moment(theDate).format('dddd  MM/DD/YY'), "initial": moment(theDate).format('MM-DD-YYYY') });
      theDate = moment(theDate).add(1, 'days');
    }
    dates.push({ "formatted": lastDate.format('dddd  MM/DD/YY'), "initial": lastDate.format('MM-DD-YYYY') });
    return dates
  }
}
