<div class="mb-16 bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 space-y-8 divide-y divide-gray-200">

    <div>
        <h3 class="text-lg leading-6 font-medium text-emerald-600">
            Receipt Preview
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Please make any necessary adjustments to your email template below.
        </p>

        <form [formGroup]="eventData" class="mt-6 space-y-8 divide-y divide-gray-200" novalidate>

            <div class="space-y-8 divide-y divide-gray-200">
                <div>

                    <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div class="sm:col-span-6">
                            <label for="name" class="block text-sm font-medium text-gray-700">
                                Receipt header text
                            </label>
                            <div class="mt-1">
                                <input type="text" name="eventExtraText" id="eventExtraText" formControlName="eventExtraText"
                                    class="shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md">
                            </div>
                            <div
                                *ngIf="!eventData.get('eventExtraText')?.valid && (eventData.get('eventExtraText')?.dirty ||eventData.get('eventExtraText')?.touched)">
                                <p [hidden]="!eventData.get('eventExtraText').errors?.required" class="mt-2 text-sm text-red-600"
                                    id="name-error">
                                    Header text is required</p>
                            </div>

                        </div>

                        <div class="sm:col-span-6">
                            <label for="name" class="block text-sm font-medium text-gray-700">
                                Receipt footer text
                            </label>
                            <div class="mt-1">
                                <input type="text" name="emailFooterText" id="emailFooterText" formControlName="emailFooterText"
                                    class="shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md">
                            </div>
                            <div
                                *ngIf="!eventData.get('emailFooterText')?.valid && (eventData.get('emailFooterText')?.dirty ||eventData.get('emailFooterText')?.touched)">
                                <p [hidden]="!eventData.get('emailFooterText').errors?.required" class="mt-2 text-sm text-red-600"
                                    id="name-error">
                                    Footer text is required</p>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </form>

        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 shadow-lg rounded-xl my-6">

            <div class="mx-auto max-w-3xl">
                <table border="0" cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                        <td align="center">
                            <table border="0" cellpadding="0" cellspacing="0" width="100%"
                                style="max-width: 600px; border-bottom: 3px solid #d4dadf;">
                                <tr>
                                    <td align="center" valign="top" style="padding: 16px 24px;"> <a
                                            href="https://myentryform.com" target="_blank"
                                            style="font-family: Helvetica, Arial, sans-serif; display: inline-block; text-decoration: none; color: #059669; font-weight: 900; font-size: 30pt;">
                                            <img src="https://myentryform.com/images/logo_block_green.png" alt="Logo"
                                                border="0" width="400"
                                                style="display: block; width: 400px; max-width: 400px;"> </a> </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>

            <div>
        
                <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
                    <!-- start copy -->
                    <br>
                    <p style="font-family: Verdana;">Thank you for your entry! </p>
                    <br>
                    <tr>
                        <td align="left" bgcolor="#ffffff"
                            style="font-family: Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                            <p style="margin: 0;">{{eventData.value['eventExtraText']}}</p>
                        </td>
                    </tr> <!-- end copy --> <!-- start receipt table -->
                    <tr>
                        <td align="center" bgcolor="#ffffff"
                            style="font-family: Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                            <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                <tr>
                                    <td align="left" width="50%"
                                        style="padding: 12px;font-family: Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                                        <strong>Item</strong>
                                    </td>
                                    <td align="left" width="25%"
                                        style="padding: 12px;font-family: Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                                        <strong>Quantity</strong>
                                    </td>
                                    <td align="left" width="25%"
                                        style="padding: 12px;font-family: Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                                        <strong>Price</strong>
                                    </td>
                                </tr>
    
                             
                                <tr *ngFor="let item of items">
                                    <td align="left" width="50%"
                                        style="padding: 6px 12px;font-family: Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                                        {{item.name}}</td>
                                    <td align="left" width="25%"
                                        style="padding: 6px 12px;font-family: Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                                        {{item.quantity}}</td>
                                    <td align="left" width="25%"
                                        style="padding: 6px 12px;font-family: Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                                        ${{item.price}}</td>
                                </tr>
                       
    
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <table border="0" cellpadding="0" cellspacing="0" width="100%">
                            <tr>
                                <td align="left" width="50%"
                                    style="padding: 12px; font-family: Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px; border-top: 2px dashed #D2C7BA; border-bottom: 2px dashed #D2C7BA;">
                                    <strong>Total</strong>
                                </td>
                                <td align="left" width="25%"
                                    style="padding: 12px; font-family: Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px; border-top: 2px dashed #D2C7BA; border-bottom: 2px dashed #D2C7BA;">
                                </td>
                                <td align="left" width="25%"
                                    style="padding: 12px; font-family: Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px; border-top: 2px dashed #D2C7BA; border-bottom: 2px dashed #D2C7BA;">
                                    <strong>$235</strong>
                                </td>
                            </tr>
                        </table>
                    </tr>
                    <tr>
                        <td align="left" bgcolor="#ffffff"
                            style="font-family: Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                            <p style="margin: 0;">{{eventData.value['emailFooterText']}}</p>
                        </td>
                    </tr>
                </table>
    
    
    
            </div>

            <div>
                <div>
                    <div style="padding: 20px;">
                        <p style="text-align: center">MyEntryForm.com</p>
                    </div>
                </div>
            </div>


        </div>

    </div>


    <mef-bottom-navigation (onBack)="backToForm()" [continueLabel]="'Submit'" (onContinue)="submit()"
        [backLabel]="'Back To Form'"></mef-bottom-navigation>

</div>