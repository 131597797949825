import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AdditionalItem } from 'src/app/core/interfaces/AdditionalItem';
import { editItemDescription, editItemName, removeItem } from 'src/app/store/actions/additional-items.actions';
import { AppState } from 'src/app/store/reducers/AppState';

@Component({
  selector: 'mefe-additional-items-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {

  @Input() data: AdditionalItem;
  isHidden: boolean = false;

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    if (this.data['name']) {
      this.isHidden = true;
    }
  }

  removeItem(id: string) {
    this.store.dispatch(removeItem({itemId: id}))
  }

  updateItemName(itemData: any, $event: any) {
    const update = {
      id: itemData._id,
      changes: {
        name: $event
      }
    }
    this.store.dispatch(editItemName({ update: update }));
  }

  updateItemDescription(itemData: any, $event: any) {
    const update = {
      id: itemData._id,
      changes: {
        notes: $event
      }
    }
    this.store.dispatch(editItemDescription({ update: update }));
  }

  updateItemPrice(itemData: any, $event: any) {
    const update = {
      id: itemData._id,
      changes: {
        price: $event
      }
    }
    this.store.dispatch(editItemDescription({ update: update }));
  }

  updateItemQuanitity(itemData: any, $event: any) {
    const update = {
      id: itemData._id,
      changes: {
        quantity: $event
      }
    }
    this.store.dispatch(editItemDescription({ update: update }));
  }

  updateItemPurchaseLimit(itemData: any, $event: any) {
    const update = {
      id: itemData._id,
      changes: {
        purchaseQuantityLimit: $event
      }
    }
    this.store.dispatch(editItemDescription({ update: update }));
  }


  showItem(id: any) {
    this.isHidden = false;
  }

  hideItem(id: any) {
    this.isHidden = true;
  }

}
