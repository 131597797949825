import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppState } from 'src/app/store/reducers/AppState';

@Injectable({
  providedIn: 'root'
})

export class RolloverService {

  constructor(private http: HttpClient) { }

  removeRollover(id: string) {
    return this.http.delete<AppState>(environment.apiUrl + 'additionalitem/' + id);
  }

}
