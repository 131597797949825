import { createAction, props } from "@ngrx/store";

export const addRollover = createAction(
    '[Rollover] Add',
    props<{ id: string, classId: string, orderId: number }>()
);

export const removeRollover = createAction(
    '[Rollover] Remove',
    props<{ id: string }>()
);

export const removeRolloverSuccess = createAction(
    '[Rollover] Remove Success',
    props<{ id: any; }>()
);

export const removeRolloverFailure = createAction(
    '[Rollover] Remove Failure',
    props<{ error: string }>()
);

export const updateRolloverOrder = createAction(
    '[Rollover] Update Order',
    props<{ payload: any }>()
);

export const editRolloverLabel = createAction(
    '[Rollover] Edit Label',
    props<{ update: any }>()
);

export const editRolloverPrice = createAction(
    '[Rollover] Edit Price',
    props<{ update: any }>()
);

export const editRolloverDate = createAction(
    '[Rollover] Edit Date',
    props<{ update: any }>()
);

export const editRolloverRollTo = createAction(
    '[Rollover] Edit RollTo',
    props<{ update: any }>()
);