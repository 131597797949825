import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, exhaustMap, mergeMap, withLatestFrom, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers/AppState';
import { removeClass, removeClassSuccess, removeClassFailure } from '../actions/classes.actions';
import { SidepotService } from 'src/app/core/services/sidepot.service';
import { ClassService } from 'src/app/core/services/class.service';
import { AdditionalItemService } from 'src/app/core/services/additional-item.service';
import { removeItem, removeItemFailure, removeItemSuccess } from '../actions/additional-items.actions';

@Injectable()
export class AdditionalItemEffects {

    constructor(
        private actions$: Actions,
        private additionalItemService: AdditionalItemService,
        private store: Store<AppState>
    ) { }

    removeAdditionalItem$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(removeItem),
            exhaustMap(action =>
                this.additionalItemService.removeAdditionalItem(action.itemId).pipe(
                    map(data => removeItemSuccess({ itemId: action.itemId })),
                    catchError((error: any) => of(removeItemFailure(error))))
            )
        )
    });
}