import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { SidePot } from 'src/app/core/interfaces/SidePot';
import { editSidepotDate, editSidepotDrawSelection, editSidepotHasQuantityLock, editSidepotName, editSidepotPrice, editSidepotQuantity, removeSidepot } from 'src/app/store/actions/sidepots.actions';
import { AppState } from 'src/app/store/reducers/AppState';

@Component({
  selector: 'mefe-sidepot',
  templateUrl: './sidepot.component.html',
  styleUrls: ['./sidepot.component.scss']
})
export class SidepotComponent implements OnInit {

  @Input() data: SidePot;
  @Input() days: any = [];

  isHidden: boolean = false;

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    if (this.data['name']) {
      this.isHidden = true;
    }
  }

  showSidepot(id: any) {
    this.isHidden = false;
  }

  hideSidepot(id: any) {
    this.isHidden = true;
  }

  removeSidepot(id: string) {
    this.store.dispatch(removeSidepot({id: id}))
  }

  updateSidepotName(sidepotData: any, $event: any) {
    const update = {
      id: sidepotData._id,
      changes: {
        name: $event
      }
    }
    this.store.dispatch(editSidepotName({ update: update }));
  }

  updateSidepotPrice(sidepotData: any, $event: any) {
    const update = {
      id: sidepotData._id,
      changes: {
        price: $event
      }
    }
    this.store.dispatch(editSidepotPrice({ update: update }));
  }

  updateSidepotDate(sidepotData: any, $event: any) {
    const update = {
      id: sidepotData._id,
      changes: {
        date: $event
      }
    }
    this.store.dispatch(editSidepotDate({ update: update }));
  }

  updateSidepotDrawSelection(sidepotData: any, $event: any) {
    const update = {
      id: sidepotData._id,
      changes: {
        drawSelection: $event
      }
    }
    this.store.dispatch(editSidepotDrawSelection({ update: update }));
  }

  updateSidepotHasQuantityLock(sidepotData: any, $event: any) {
    const update = {
      id: sidepotData._id,
      changes: {
        hasQuantityLock: $event
      }
    }
    this.store.dispatch(editSidepotHasQuantityLock({ update: update }));
  }

  updateSidepotQuantity(sidepotData: any, $event: any) {
    const update = {
      id: sidepotData._id,
      changes: {
        quantity: $event
      }
    }
    this.store.dispatch(editSidepotQuantity({ update: update }));
  }

}
