import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, exhaustMap, mergeMap, withLatestFrom, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers/AppState';
import { removeSidepot, removeSidepotFailure, removeSidepotSuccess } from '../actions/sidepots.actions';
import { SidepotService } from 'src/app/core/services/sidepot.service';

@Injectable()
export class SidepotEffects {

    constructor(
        private actions$: Actions,
        private sidepotService: SidepotService,
        private store: Store<AppState>
    ) { }

    removeSidepot$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(removeSidepot),
            exhaustMap(action =>
                this.sidepotService.removeSidepot(action.id).pipe(
                    map(data => removeSidepotSuccess({ id: action.id })),
                    catchError((error: any) => of(removeSidepotFailure(error))))
            )
        )
    });
}