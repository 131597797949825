<div class="mb-16 bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 space-y-8 divide-y divide-gray-200">

    <div>
        <h3 class="text-lg leading-6 font-medium text-emerald-600">
            Event Information
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Please fill out the form below.
        </p>

        <form [formGroup]="eventData" class="mt-6 space-y-8 divide-y divide-gray-200" novalidate>

            <div class="space-y-8 divide-y divide-gray-200">
                <div>
                    <div class="col-span-full flex items-center gap-x-8">
                        <img [src]="logo"
                            alt="" class="h-24 w-auto flex-none object-cover">
                        <div>
                            <button type="button" #upload (click)="file.click()"
                                class="rounded-md bg-emerald-600 hover:bg-emerald-700 px-3 py-2 text-sm font-semibold text-white shadow-sm">Change
                                logo {{logoState?.progress > 0 ? logoState?.progress + '%' : '' }}</button>
                            <p class="mt-2 text-xs leading-5 text-gray-400">Only PNG files. 10MB max.</p>
                            <span *ngIf="logoState?.status != 'Ready' && logoState?.status != 'Failed'" class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">{{logoState?.status}}</span>
                            <span *ngIf="logoState?.status == 'Failed'" class="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">{{logoState?.error}}</span>
                        </div>
                    </div>

                    <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div class="sm:col-span-6">
                            <label for="name" class="block text-sm font-medium text-gray-700">
                                Name
                            </label>
                            <div class="mt-1">
                                <input type="text" name="name" id="name" autocomplete="name" formControlName="name"
                                    class="shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md">
                            </div>
                            <div
                                *ngIf="!eventData.get('name')?.valid && (eventData.get('name')?.dirty ||eventData.get('name')?.touched)">
                                <p [hidden]="!eventData.get('name').errors?.required" class="mt-2 text-sm text-red-600"
                                    id="name-error">
                                    Name is required</p>
                                <p [hidden]="!eventData.get('name').errors?.minlength" class="mt-2 text-sm text-red-600"
                                    id="name-error">
                                    Name must be longer than 3 letters</p>
                            </div>

                        </div>

                        <div class="sm:col-span-3">
                            <label for="start" class="block text-sm font-medium text-gray-700">
                                Start Date
                            </label>
                            <div class="mt-1">
                                <input type="text" autocomplete="off" name="startDate" id="startDate"
                                    class="shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                    [dpDayPicker]="config" formControlName="start">
                            </div>
                            <div
                                *ngIf="!eventData.get('start')?.valid && (eventData.get('start')?.dirty ||eventData.get('address')?.touched)">
                                <p [hidden]="!eventData.get('start').errors?.required" class="mt-2 text-sm text-red-600"
                                    id="name-error">
                                    Start Date is required</p>
                            </div>

                        </div>

                        <div class="sm:col-span-3">
                            <label for="end" class="block text-sm font-medium text-gray-700">
                                End Date
                            </label>
                            <div class="mt-1">
                                <input type="text" autocomplete="off" name="end" id="end"
                                    class="shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                    [dpDayPicker]="config" formControlName="end">
                            </div>
                            <div
                                *ngIf="!eventData.get('end')?.valid && (eventData.get('end')?.dirty ||eventData.get('end')?.touched)">
                                <p [hidden]="!eventData.get('end').errors?.required" class="mt-2 text-sm text-red-600"
                                    id="name-error">
                                    End Date is required</p>
                            </div>

                        </div>

                        <div class="sm:col-span-3">
                            <label for="openingDateTime" class="block text-sm font-medium text-gray-700">
                                Online Entry Opening DateTime
                            </label>
                            <div class="mt-1">
                                <input type="text" name="openingDateTime" id="openingDateTime"
                                    formControlName="openingDateTime" [dpDayPicker]="dateTimeConfig" [mode]="'daytime'"
                                    class="shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md">
                            </div>
                            <div
                                *ngIf="!eventData.get('openingDateTime')?.valid && (eventData.get('openingDateTime')?.dirty ||eventData.get('openingDateTime')?.touched)">
                                <p [hidden]="!eventData.get('openingDateTime').errors?.required"
                                    class="mt-2 text-sm text-red-600" id="name-error">
                                    Online Entry Opening DateTime is required</p>
                            </div>

                        </div>

                        <div class="sm:col-span-3">
                            <label for="end" class="block text-sm font-medium text-gray-700">
                                Online Entry Closing DateTime
                            </label>
                            <div class="mt-1">
                                <input type="text" name="closingDateTime" id="closingDateTime"
                                    formControlName="closingDateTime" [dpDayPicker]="dateTimeConfig" [mode]="'daytime'"
                                    class="shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md">
                            </div>
                            <div
                                *ngIf="!eventData.get('closingDateTime')?.valid && (eventData.get('closingDateTime')?.dirty ||eventData.get('closingDateTime')?.touched)">
                                <p [hidden]="!eventData.get('closingDateTime').errors?.required"
                                    class="mt-2 text-sm text-red-600" id="name-error">
                                    Online Entry Closing DateTime</p>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </form>

    </div>


    <mef-bottom-navigation (onContinue)="continueToFormBuilder()" [continueLabel]="'Continue'"
        [disableBack]="true"></mef-bottom-navigation>

</div>

<input #file type="file" accept="image/*" (change)="uploadLogo(file.files)" [style.visibility]="'hidden'">