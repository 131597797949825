<div cdkDropList (cdkDropListDropped)="drop($event)"  class="mt-6 divide-y divide-gray-200 rounded-br-md rounded-bl-md">
    <div cdkDrag *ngFor="let itemData of items; trackBy:custom">

        <mefe-additional-items-item [data]="itemData"></mefe-additional-items-item>

    </div>
  </div>

  <div class="mt-6 relative">
    <div class="absolute inset-0 flex items-center" aria-hidden="true">
        <div class="w-full border-t border-gray-300"></div>
    </div>
    <div class="relative flex justify-center">

        <button (click)="addAdditionalItem()" type="button" class="inline-flex items-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2">
            <svg class="-ml-1 mr-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
            </svg>
            Add Item
          </button>

      
    </div>
</div>