import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { ClassPrice } from "src/app/core/interfaces/ClassPrice";
import { updateClassPrice } from "../actions/class-price.actions";
import { getEditSuccess } from "../actions/edit.actions";


export interface ClassPriceState extends EntityState<ClassPrice> { }

export const classPriceAdapter: EntityAdapter<ClassPrice> = createEntityAdapter<ClassPrice>();
export const initialClassPriceState: ClassPriceState = classPriceAdapter.getInitialState();
export const classPriceReducer = createReducer(
    initialClassPriceState,
    on(updateClassPrice, (state, action) => classPriceAdapter.upsertOne(action.update, state)),
    on(getEditSuccess, (state, { data } )=> classPriceAdapter.addMany([...data.classPrices], state)),
    );

export const { selectAll, selectEntities } = classPriceAdapter.getSelectors();