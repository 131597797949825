import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, exhaustMap } from 'rxjs/operators';
import { OrganizationService } from 'src/app/core/services/organization.service';
import { getOrganization, getOrganizationSuccess, getOrganizationFailure } from '../actions/organization.actions';

@Injectable()
export class OrganizationEffects {

    constructor(
        private actions$: Actions,
        private organizationService: OrganizationService
    ) { }

    getOrganization$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getOrganization),
            exhaustMap(action =>
                this.organizationService.getEvent(action.id).pipe(
                    map(event => getOrganizationSuccess({ data: event })),
                    catchError((error: any) => of(getOrganizationFailure(error))))
            )
        )
    });

}