import { createSelector, select } from '@ngrx/store';
import { AppState } from '../reducers/AppState';
import { Event } from 'src/app/core/interfaces/Event';
import * as fromClasses from '../reducers/classes.reducers';
import * as fromClassPrices from '../reducers/class-price.reducers';
import * as fromSidepots from '../reducers/sidepots.reducers';
import * as fromAdditionalItems from '../reducers/additional-items.reducers';
import * as fromRollovers from '../reducers/rollovers.reducers';
import { Class } from 'src/app/core/interfaces/Class';
import { ClassPrice } from 'src/app/core/interfaces/ClassPrice';
import { SidePot } from 'src/app/core/interfaces/SidePot';
import { AdditionalItem } from 'src/app/core/interfaces/AdditionalItem';
import { Rollover } from 'src/app/core/interfaces/Rollover';
import { Organization } from 'src/app/core/interfaces/Organization';

export const selectOrganizationState = (state: AppState) => state.organization;
export const selectEventState = (state: AppState) => state.event;
export const selectClassesState = (state: AppState) => state.classes;
export const selectClassPricesState = (state: AppState) => state.classPrices;
export const selectSidepotsState = (state: AppState) => state.sidepots;
export const selectAdditionalItemsState = (state: AppState) => state.additionalItems;
export const selectRolloversState = (state: AppState) => state.rollovers;


export const selectClasses = createSelector(
    selectClassesState,
    fromClasses.selectAll
);

export const selectClassPrices= createSelector(
    selectClassPricesState,
    fromClassPrices.selectAll
);

export const selectSidepots= createSelector(
    selectSidepotsState,
    fromSidepots.selectAll
);

export const selectAdditionalItems= createSelector(
    selectAdditionalItemsState,
    fromAdditionalItems.selectAll
);

export const selectRollovers= createSelector(
    selectRolloversState,
    fromRollovers.selectAll
);


export const selectAll = createSelector(
    selectOrganizationState,
    selectEventState,
    selectClasses,
    selectClassPrices,
    selectSidepots,
    selectAdditionalItems,
    selectRollovers,
    (organization: Organization, eventData: Event, classes: Class[], classPrices: ClassPrice[], sidepots: SidePot[], additionalItems: AdditionalItem[], rollovers: Rollover[]) => {
        return {
            organization: organization,
            event: eventData,
            classes: classes,
            classPrices: classPrices,
            sidepots: sidepots,
            additionalItems: additionalItems,
            rollovers: rollovers
        }
    }
)
