import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppState } from 'src/app/store/reducers/AppState';

@Injectable({
  providedIn: 'root'
})

export class SidepotService {

  constructor(private http: HttpClient) { }

  removeSidepot(id: string) {
    return this.http.delete<AppState>(environment.apiUrl + 'event-builder/removeSidepot/' + id);
  }

}
