import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Class } from 'src/app/core/interfaces/Class';
import { Rollover } from 'src/app/core/interfaces/Rollover';
import { editRolloverDate, editRolloverLabel, editRolloverPrice, editRolloverRollTo, removeRollover } from 'src/app/store/actions/rollovers.actions';
import { AppState } from 'src/app/store/reducers/AppState';

@Component({
  selector: 'mefe-rollover',
  templateUrl: './rollover.component.html',
  styleUrls: ['./rollover.component.scss']
})
export class RolloverComponent implements OnInit {

  @Input() data: Rollover;
  @Input() days: any = [];
  @Input() classes: Class[] = [];

  isHidden: boolean = false;

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
  }

  showRollover(id: any) {
    this.isHidden = false;
  }

  hideRollover(id: any) {
    this.isHidden = true;
  }

  removeRollover(id: string) {
    this.store.dispatch(removeRollover({id: id}))
  }

  updateRolloverPrice(rolloverData: any, $event: any) {
    const update = {
      id: rolloverData._id,
      changes: {
        price: $event
      }
    }
    this.store.dispatch(editRolloverPrice({ update: update }));
  }

  updateRolloverDate(rolloverData: any, $event: any) {
    const update = {
      id: rolloverData._id,
      changes: {
        date: $event
      }
    }
    this.store.dispatch(editRolloverDate({ update: update }));
  }

  updateRolloverRollTo(rolloverData: any, $event: any) {
    console.log("Rollover Data", rolloverData);
    const update = {
      id: rolloverData._id,
      changes: {
        rollTo: $event
      }
    }
    this.store.dispatch(editRolloverRollTo({ update: update }));
  }

}
