import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, exhaustMap, mergeMap, withLatestFrom, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers/AppState';
import { removeClass, removeClassSuccess, removeClassFailure } from '../actions/classes.actions';
import { SidepotService } from 'src/app/core/services/sidepot.service';
import { ClassService } from 'src/app/core/services/class.service';

@Injectable()
export class ClassEffects {

    constructor(
        private actions$: Actions,
        private classService: ClassService,
        private store: Store<AppState>
    ) { }

    removeClass$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(removeClass),
            exhaustMap(action =>
                this.classService.removeClass(action.classId).pipe(
                    map(data => removeClassSuccess({ id: action.classId })),
                    catchError((error: any) => of(removeClassFailure(error))))
            )
        )
    });
}