import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { addItem, editItemDescription, editItemName, editItemPrice, editItemPurchaseLimit, editItemQuantity, removeItem, updateItemOrder } from "../actions/additional-items.actions";
import { AdditionalItem } from "src/app/core/interfaces/AdditionalItem";
import { updateClassesOrder } from "../actions/classes.actions";
import { getEditSuccess } from "../actions/edit.actions";


export interface AdditionalItemsState extends EntityState<AdditionalItem> { }

export const additionalItemsAdapter: EntityAdapter<AdditionalItem> = createEntityAdapter<AdditionalItem>({
  sortComparer: (item1: AdditionalItem, item2: AdditionalItem) => item1.orderId - item2.orderId,
  selectId: (item: AdditionalItem) => item._id
});

export const initialAdditionalItemsState: AdditionalItemsState = additionalItemsAdapter.getInitialState();

export const additionalItemsReducer = createReducer(
  initialAdditionalItemsState,
  on(addItem, (state, action) => additionalItemsAdapter.addOne({_id: action.itemId, orderId: action.orderId, name: '', notes: '', quantity: '', purchaseQuantityLimit: '', price: ''}, state)),
  on(removeItem, (state, action) => additionalItemsAdapter.removeOne(action.itemId, state)),
  on(editItemName, (state, action) => additionalItemsAdapter.updateOne(action.update, state)),
  on(editItemDescription, (state, action) => additionalItemsAdapter.updateOne(action.update, state)),
  on(editItemPrice, (state, action) => additionalItemsAdapter.updateOne(action.update, state)),
  on(editItemQuantity, (state, action) => additionalItemsAdapter.updateOne(action.update, state)),
  on(editItemPurchaseLimit, (state, action) => additionalItemsAdapter.updateOne(action.update, state)),
  on(updateItemOrder, (state, action) => additionalItemsAdapter.updateMany(action.payload, state)),
  on(getEditSuccess, (state, { data } )=> additionalItemsAdapter.addMany([...data.additionalItems], state)),
);

export const { selectAll } = additionalItemsAdapter.getSelectors();