import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'mef-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();

  userFullName: string = "";

  constructor(private userservice: UserService) {
  }

  ngOnInit(): void {
    this.userservice.user
    .pipe(takeUntil(this.destroy$)).subscribe(x => {
      this.userFullName = x.name;
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  userMenuOpen: boolean = false;

  toggleUserMenu() {
    this.userMenuOpen = !this.userMenuOpen;
  }


  logout() {
    this.userservice.logout();

  }

  settings() {

  }


}
