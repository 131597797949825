<div class="mt-6">
  <div *ngIf="!disableButtons">
    <div class="mt-6">
      <button [disabled]="isInvalid" (click)="continue()"
        class="w-full flex justify-center items-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white"
        [ngClass]="isInvalid == false ? 'bg-emerald-600 hover:bg-emerald-700' : 'bg-gray-400 hover:bg-emerald-500'">{{continueLabel}}</button>
    </div>
    <div *ngIf="!disableBack" class="mt-6 flex justify-center text-sm text-center text-gray-500">
      <p>
        or <button (click)="back()" type="button" class="text-emerald-600 font-medium hover:text-emerald-500"><span
            aria-hidden="true"> &larr;</span>{{backLabel}}</button>
      </p>
    </div>
  </div>
</div>