import { createAction, props } from "@ngrx/store";

export const addItem = createAction(
    '[Additional Item] Add',
    props<{ itemId: string, orderId: number }>()
);

export const removeItem = createAction(
    '[Additional Item] Remove',
    props<{ itemId: string }>()
);

export const removeItemSuccess = createAction(
    '[Additional Item] Remove Success',
    props<{ itemId: any; }>()
);

export const removeItemFailure = createAction(
    '[Additional Item] Remove Failure',
    props<{ error: string }>()
);


export const updateItemOrder = createAction(
    '[Additional Item] Update Classes Order',
    props<{ payload: any }>()
);

export const editItemName = createAction(
    '[Additional Item] Edit Item Name',
    props<{ update: any }>()
);

export const editItemDescription = createAction(
    '[Additional Item] Edit Item Description',
    props<{ update: any }>()
);

export const editItemPrice = createAction(
    '[Additional Item] Edit Item Price',
    props<{ update: any }>()
);

export const editItemQuantity = createAction(
    '[Additional Item] Edit Item Quantity',
    props<{ update: any }>()
);

export const editItemPurchaseLimit = createAction(
    '[Additional Item] Edit Item Quantity',
    props<{ update: any }>()
);

