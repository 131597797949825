import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject, map, takeUntil } from 'rxjs';
import { saveEdit, saveEditSuccess } from 'src/app/store/actions/edit.actions';
import { submitForm, submitFormSuccess } from 'src/app/store/actions/form.actions';
import { clearStore } from 'src/app/store/actions/helper.actions';
import { AppState } from 'src/app/store/reducers/AppState';
import { selectEditStatus } from 'src/app/store/selectors/edit.selectors';
import { selectEventId } from 'src/app/store/selectors/event.selectors';
import { selectOrganizationId } from 'src/app/store/selectors/organization.selectors';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();

  organizationId: string = "";
  eventId: string = "";
  isEdit: boolean = false;

  constructor(private router: Router, private store: Store<AppState>, private actions$: Actions) {
    this.store.select(selectOrganizationId)
      .pipe(takeUntil(this.destroy$)).subscribe(id => {
        this.organizationId = id;
      });

  }

  ngOnInit(): void {
    console.log('Router Url', this.router.url);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  backToEvent() {
    this.router.navigate(['event', this.organizationId]);
  }

  previewReceipt() {
    this.router.navigate(['receipt-preview']);
  }

}
