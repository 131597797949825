
import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers/AppState';
import * as fromAdditionalItems from '../reducers/additional-items.reducers';

export const selectAdditionalItemsState = (state: AppState) => state.additionalItems;

export const selectAdditionalItems = createSelector(
    selectAdditionalItemsState,
    fromAdditionalItems.selectAll
);
