import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, exhaustMap, withLatestFrom, mergeMap } from 'rxjs/operators';
import { EditService } from 'src/app/core/services/edit.service';
import { getEditData, getEditFailure, getEditSuccess, saveEdit, saveEditFailure, saveEditSuccess } from '../actions/edit.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers/AppState';
import { selectAll } from '../selectors/get-all.selectors';

@Injectable()
export class EditEffects {

    constructor(
        private actions$: Actions,
        private editService: EditService,
        private store: Store<AppState>
    ) { }

    editData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getEditData),
            exhaustMap(action =>
                this.editService.getData(action.id).pipe(
                    map(data => getEditSuccess({ data: data })),
                    catchError((error: any) => of(getEditFailure(error))))
            )
        )
    });

    saveEdit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(saveEdit),
            withLatestFrom(this.store.select(selectAll)),
            mergeMap(([action, data]) =>
                this.editService.saveEdit(action.id, data).pipe(
                    map((data: any) => saveEditSuccess({eventId: data.eventId})),
                    catchError((error: any) => of(saveEditFailure(error))))
            )
        )
    });

}