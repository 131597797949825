import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppState } from 'src/app/store/reducers/AppState';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../interfaces/user';

@Injectable({
    providedIn: 'root'
})

export class UserService {

    public activeUser: BehaviorSubject<any>;
    public user: Observable<User>;

    constructor(private http: HttpClient) {
        // @ts-ignore
        this.activeUser = new BehaviorSubject<{}>(JSON.parse(localStorage.getItem('user')));
        this.user = this.activeUser.asObservable();

    }

    getUser() {
        this.http.get<AppState>(environment.apiUrl + 'user/cookieuser', {withCredentials: true}).subscribe((userData: any) => {
            if (userData) {

                var obj = {
                  "_id": "",
                  "name": "",
                  "email": "",
                  "company": "",
                  "token": ""
                };
      
                obj['_id'] = userData._id;
                obj['name'] = userData.name;
                obj['email'] = userData.email;
                obj['company'] = userData.company;
      
                // store user, token local to keep user logged in refreshes
                localStorage.setItem('user', JSON.stringify(obj));
                this.activeUser.next(obj);
            } else {
                window.location.href = "https://admin.myentryform.com";
            }
        });
    }

    logout() {
        return this.http.get<any>(environment.apiUrl + 'user/logout', {withCredentials: true}).subscribe(data => {
          this.activeUser.next(null);
          localStorage.removeItem('user');
          window.location.href = "https://admin.myentryform.com";
        })
      }

}
