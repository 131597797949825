import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, exhaustMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers/AppState';
import { RolloverService } from 'src/app/core/services/rollover.service';
import { removeRollover, removeRolloverFailure, removeRolloverSuccess } from '../actions/rollovers.actions';

@Injectable()
export class RolloverEffects {

    constructor(
        private actions$: Actions,
        private rolloverService: RolloverService,
        private store: Store<AppState>
    ) { }

    removeRollover$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(removeRollover),
            exhaustMap(action =>
                this.rolloverService.removeRollover(action.id).pipe(
                    map(data => removeRolloverSuccess({ id: action.id })),
                    catchError((error: any) => of(removeRolloverFailure(error))))
            )
        )
    });
}