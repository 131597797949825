import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppState } from 'src/app/store/reducers/AppState';

@Injectable({
  providedIn: 'root'
})

export class FormService {

  constructor(private http: HttpClient) { }

  submitForm(data: any) {
    return this.http.post<AppState>(environment.apiUrl + 'event-builder/create/', data);
  }


}
