import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { addItem, updateItemOrder } from 'src/app/store/actions/additional-items.actions';
import { AppState } from 'src/app/store/reducers/AppState';
import * as uuid from "uuid";
import { CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray } from '@angular/cdk/drag-drop';
import { Update } from '@ngrx/entity';
import { AdditionalItem } from 'src/app/core/interfaces/AdditionalItem';
import { selectAdditionalItems } from 'src/app/store/selectors/additional-items.selectors';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'mefe-additional-items',
  templateUrl: './additional-items.component.html',
  styleUrls: ['./additional-items.component.scss']
})
export class AdditionalItemsComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();

  items: AdditionalItem[] = [];

  constructor(private store: Store<AppState>) {

  }

  ngOnInit(): void {
    this.store.select(selectAdditionalItems)
    .pipe(takeUntil(this.destroy$)).subscribe(items => {
      this.items = items;
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  addAdditionalItem() {
    this.store.dispatch(addItem({ itemId: uuid.v4(), orderId: this.items.length }));
  }

  custom(index: number, item: any) {
    return index;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);

    const itemUpdates: Update<AdditionalItem>[] = this.items.map((item: AdditionalItem, index) => {
      return {
        id: item._id,
        changes: {
          orderId: index
        }
      };
    });

    this.store.dispatch(updateItemOrder({ payload: itemUpdates }));

  }


}
