import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { Subject, map, takeUntil, tap } from 'rxjs';
import { Class } from 'src/app/core/interfaces/Class';
import { Rollover } from 'src/app/core/interfaces/Rollover';
import { addRollover, updateRolloverOrder } from 'src/app/store/actions/rollovers.actions';
import { AppState } from 'src/app/store/reducers/AppState';
import { selectClasses } from 'src/app/store/selectors/classes.selectors';
import { selectRollovers } from 'src/app/store/selectors/rollovers.selectors';
import * as uuid from "uuid";

@Component({
  selector: 'mefe-rollovers',
  templateUrl: './rollovers.component.html',
  styleUrls: ['./rollovers.component.scss']
})
export class RolloversComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();

  rollovers: Rollover[] = [];
  allRollovers: Rollover[] = [];
  @Input() classId: any;
  @Input() days: any = [];
  classes: Class[] = [];

  constructor(private store: Store<AppState>) {
  }

  ngOnInit(): void {

    this.store.select(selectRollovers).pipe(
      tap(rollovers => this.allRollovers = rollovers),
      map(rollovers => rollovers.filter(rollover => rollover.class === this.classId)),
      takeUntil(this.destroy$)
    ).subscribe(AvailableRollovers => {
      this.rollovers = AvailableRollovers;
    });

    this.store.select(selectClasses).pipe(
      map(classes => classes.filter(c => c._id !== this.classId)),
      takeUntil(this.destroy$)
    )
      .subscribe(classes => {
        this.classes = classes;
      });

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  custom(index: number, item: any) {
    return index;
  }

  addRollover() {
    this.store.dispatch(addRollover({ id: uuid.v4(), orderId: this.allRollovers.length, classId: this.classId }));
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.rollovers, event.previousIndex, event.currentIndex);

    const rolloversUpdate: Update<Rollover>[] = this.rollovers.map((item: Rollover, index) => {
      return {
        id: item._id,
        changes: {
          orderId: index
        }
      };
    });

    this.store.dispatch(updateRolloverOrder({ payload: rolloversUpdate }));

  }

}
