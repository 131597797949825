
import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers/AppState';
import * as fromClassPrices from '../reducers/class-price.reducers';

export const selectClassPriceState = (state: AppState) => state.classPrices;

export const selectClassPrices = createSelector(
    selectClassPriceState,
    fromClassPrices.selectEntities
);
