import { Injectable } from '@angular/core';
import { Actions, Effect, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { map, catchError, exhaustMap, concatMap, takeUntil } from 'rxjs/operators';
import { LogoService } from 'src/app/core/services/logo.service';
import { UPLOAD_CANCEL, UPLOAD_COMPLETED, UPLOAD_FAILURE, UPLOAD_PROGRESS, UPLOAD_REQUEST, UPLOAD_STARTED } from '../actions/logo.actions';
import { Action, Store } from '@ngrx/store';
import { HttpEvent, HttpEventType } from '@angular/common/http';

@Injectable()
export class LogoEffects {

    constructor(
        private actions$: Actions,
        private store: Store,
        private logoService: LogoService
    ) { }


    uploadLogo$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UPLOAD_REQUEST),
            concatMap(action =>
                this.logoService.uploadLogo(action.id, action.file).pipe(
                    takeUntil(
                        this.actions$.pipe(
                            ofType(UPLOAD_CANCEL)
                        )
                    ),
                    map(event => this.getActionFromHttpEvent(event)),
                    catchError(error => of(UPLOAD_FAILURE({
                        error: `Unknown Event: ${JSON.stringify(event)}`
                    })))
                )
            )
        )
    });

    private getActionFromHttpEvent(event: HttpEvent<any>) {
        switch (event.type) {
            case HttpEventType.Sent: {
                return UPLOAD_STARTED()
            }
            case HttpEventType.UploadProgress: {
                return UPLOAD_PROGRESS({
                    //@ts-ignore
                    progress: Math.round((100 * event.loaded) / event.total)
                });
            }
            case HttpEventType.ResponseHeader:
            case HttpEventType.Response: {
                if (event.status === 200) {
                    return UPLOAD_COMPLETED()
                } else {
                    return UPLOAD_FAILURE({
                        error: event.statusText
                    })
                }
            }
            default: {
                return UPLOAD_FAILURE({
                    error: `Unknown Event: ${JSON.stringify(event)}`
                })
            }
        }
    }


}