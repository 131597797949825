import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { Class } from "src/app/core/interfaces/Class";
import { addClass, editClassDescription, editClassName, removeClass, updateClassesOrder } from "../actions/classes.actions";
import { getEditSuccess } from "../actions/edit.actions";
import * as uuid from "uuid";

export interface ClassesState extends EntityState<Class> { }

export const classesAdapter: EntityAdapter<Class> = createEntityAdapter<Class>({
    selectId: (eventClass: Class) => eventClass._id,
    sortComparer: (class1: Class, class2: Class) => class1.orderId - class2.orderId
 });
export const initialClassesState: ClassesState = classesAdapter.getInitialState();
export const classesReducer = createReducer(
    initialClassesState,
    on(addClass, (state, action) => classesAdapter.addOne({ _id: action.classId, orderId: action.orderId, name: '', description: '' }, state)),
    on(removeClass, (state, action) => classesAdapter.removeOne(action.classId, state)),
    on(editClassName, (state, action) => classesAdapter.updateOne(action.update, state)),
    on(editClassDescription, (state, action) => classesAdapter.updateOne(action.update, state)),
    on(updateClassesOrder, (state, action) => classesAdapter.updateMany(action.payload, state)),
    on(getEditSuccess, (state, { data } )=> classesAdapter.addMany([...data.classes], state)),
    );

export const { selectAll, selectEntities } = classesAdapter.getSelectors();