import { createAction, props } from '@ngrx/store';
import { AppState } from '../reducers/AppState';


export const setEditStatus = createAction(
    '[Edit Set] Setting Edit Status',
    props<{ status: any; }>()
);

export const getEditData = createAction(
    '[Edit Event] Get',
    props<{ id: any; }>()
);

export const getEditSuccess = createAction(
    '[Edit Event] Get Success',
    props<{ data: AppState; }>()
);

export const getEditFailure = createAction(
    '[Edit Event] Get Failure',
    props<{ error: string }>()
);

export const saveEdit = createAction('[Edit] Save',
    props<{ id: any; }>());

export const saveEditSuccess = createAction('[Edit] Save Success',
props<{ eventId: any; }>());

export const saveEditFailure = createAction(
    '[Edit] Save Failure',
    props<{ error: string }>()
);
