import { createSelector, select } from '@ngrx/store';
import { AppState } from '../reducers/AppState';
import * as fromSidepots from '../reducers/sidepots.reducers';
import { filter, pipe } from 'rxjs';

export const selectSidepotsState = (state: AppState) => state.sidepots;

export const selectSidepots = createSelector(
    selectSidepotsState,
    fromSidepots.selectAll
);

export const selectSidepotsByClassId = (classId: string) =>
  createSelector(selectSidepots, state => state.filter(x => x.eventClass == classId));
