import { Action, createReducer, on } from "@ngrx/store";
import { UploadStatus } from "src/app/core/enums/UploadStatus";
import { Logo } from "src/app/core/interfaces/Logo";
import { UPLOAD_CANCEL, UPLOAD_COMPLETED, UPLOAD_FAILURE, UPLOAD_PROGRESS, UPLOAD_REQUEST, UPLOAD_RESET, UPLOAD_STARTED } from "../actions/logo.actions";

export const initialLogoUploadState: Logo = {
    status: UploadStatus.Ready,
    error: null,
    progress: null
};

const logoUploadReducer = createReducer(
    initialLogoUploadState,
    on(UPLOAD_REQUEST, (state) => ({
        ...state, status: UploadStatus.Requested,
        progress: null,
        error: null
    })),
    on(UPLOAD_CANCEL, (state) => ({
        ...state, status: UploadStatus.Ready,
    })),
    on(UPLOAD_RESET, (state) => ({
        ...state, status: UploadStatus.Ready,
    })),
    on(UPLOAD_FAILURE, (state, payload) => ({
        ...state, status: UploadStatus.Failed,
        error: payload.error
    })),
    on(UPLOAD_STARTED, (state) => ({
        ...state, status: UploadStatus.Started,
        progress: 0,
    })),
    on(UPLOAD_PROGRESS, (state, payload) => ({
        ...state, 
        progress: payload.progress
    })),
    on(UPLOAD_COMPLETED, (state, payload) => ({
        ...state, 
        status: UploadStatus.Completed,
        progress: 100,
        error: null
    })),
);

export function logoUploadReducers(state = initialLogoUploadState, action: Action) {
    return logoUploadReducer(state, action);
}