import { createAction, props } from "@ngrx/store";

export const addClass = createAction(
    '[Class] Add',
    props<{ classId: string, orderId: number }>()
);

export const removeClass = createAction(
    '[Class] Remove',
    props<{ classId: string }>()
);

export const removeClassSuccess = createAction(
    '[Class] Remove Success',
    props<{ id: any; }>()
);

export const removeClassFailure = createAction(
    '[Class] Remove Failure',
    props<{ error: string }>()
);


export const updateClassesOrder = createAction(
    '[Class] Update Classes Order',
    props<{ payload: any }>()
);

export const editClassName = createAction(
    '[Class] Edit Class Name',
    props<{ update: any }>()
);

export const editClassDescription = createAction(
    '[Class] Edit Class Description',
    props<{ update: any }>()
);
