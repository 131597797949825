import { Action, createReducer, on } from "@ngrx/store";
import { setEditStatus } from "../actions/edit.actions";
import { Edit } from "src/app/core/interfaces/Edit";

export const initialEditState: Edit = {
    status: false
};

const EditReducer = createReducer(
    initialEditState,
    on(setEditStatus, (state, { status }) => ({ ...state, status: status }))
);

export function EditReducers(state = initialEditState, action: Action) {
    return EditReducer(state, action);
}