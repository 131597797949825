import { Action, createReducer, on } from "@ngrx/store";
import { Event } from "src/app/core/interfaces/Event";
import { updateEventInfo } from "../actions/event.actions";
import { getEditSuccess } from "../actions/edit.actions";
import { submitFormSuccess } from "../actions/form.actions";

export const initialEventState: Event = {
    _id: "",
    name: "",
    start: "",
    end: "",
    openingDateTime: "", 
    closingDateTime: "",
    eventExtraText: "",
    emailFooterText: "",
    cashOrCheckText: "",
    logo: ""
};

const eventReducer = createReducer(
    initialEventState,
    on(updateEventInfo, (state, { data } )=> ({ ...state, ...data })),
    on(getEditSuccess, (state, { data } )=> ({ ...state, ...data.event })),
    on(submitFormSuccess, (state, { eventId } )=> ({ ...state, _id: eventId })),
);

export function eventReducers(state = initialEventState, action: Action) {
    return eventReducer(state, action);
}