import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, exhaustMap, mergeMap, withLatestFrom, tap } from 'rxjs/operators';
import { submitForm, submitFormFailure, submitFormSuccess } from '../actions/form.actions';
import { FormService } from 'src/app/core/services/form.service';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers/AppState';
import { selectAll } from '../selectors/get-all.selectors';

@Injectable()
export class FormEffects {

    constructor(
        private actions$: Actions,
        private formService: FormService,
        private store: Store<AppState>
    ) { }

    submitForm$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(submitForm),
            withLatestFrom(this.store.select(selectAll)),
            mergeMap(([actions, data]) =>
                this.formService.submitForm(data).pipe(
                    map((data: any) => submitFormSuccess({eventId: data.eventId})),
                    catchError((error: any) => of(submitFormFailure({error: error})).pipe(tap(error => {
                        console.log(error);
                    }))))
            )
        )
    });
}