import { Action, createReducer, on } from "@ngrx/store";
import { AppState } from "./AppState";
import { getOrganizationSuccess } from "../actions/organization.actions";
import { Organization } from "src/app/core/interfaces/Organization";
import { getEditSuccess } from "../actions/edit.actions";

export const initialOrganizationState: Organization = {
    _id: "",
    name: "",
};

const organizationReducer = createReducer(
    initialOrganizationState,
    on(getOrganizationSuccess, (state, { data } )=> ({ ...state, ...data })),
    on(getEditSuccess, (state, { data } )=> ({ ...state, ...data.organization })),
);

export function organizationReducers(state = initialOrganizationState, action: Action) {
    return organizationReducer(state, action);
}