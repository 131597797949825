import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { updateClassPrice } from 'src/app/store/actions/class-price.actions';
import { AppState } from 'src/app/store/reducers/AppState';

@Component({
  selector: 'mefe-class-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent implements OnInit {

  @Input() data: any;
  @Input() classId: any;
  @Input() price: any;

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
  }

  updateClassPrice(date: any, $event: any) {
    const update = {
      id: this.classId + date,
      date: date,
      classId: this.classId,
      price: $event
    }
    this.store.dispatch(updateClassPrice({ update: update }));
  }

}
